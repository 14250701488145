import React from 'react';

function WeatherDisplay({ data }) {
  return (
    <div className="weather-display">
      <h2>{data.name} ({data.main.temp} °C)</h2>
      <p>Feels like: {data.main.feels_like} °C</p>
      <p>Min Temp: {data.main.temp_min} °C</p>
      <p>Max Temp: {data.main.temp_max} °C</p>
      <p>Sunrise: {new Date(data.sys.sunrise * 1000).toLocaleTimeString()}</p>
      <p>Sunset: {new Date(data.sys.sunset * 1000).toLocaleTimeString()}</p>
    </div>
  );
}

export default WeatherDisplay;
