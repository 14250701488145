import React, { useState, useEffect } from 'react';
import WeatherDisplay from './WeatherDisplay';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function WeatherForm() {
  const [city, setCity] = useState('');
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [weatherData, setWeatherData] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);

  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude);
        setLon(position.coords.longitude);
      });
    }

   
    const savedSearches = JSON.parse(localStorage.getItem('recentSearches'));
    if (savedSearches) {
      setRecentSearches(savedSearches);
    }
  }, []);

  const fetchCoordinates = (cityName) => {
    const apiKey = 'bb5c5a1a91c1d5b91b734c519e7f494d';
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${apiKey}&units=metric`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.coord) {
          setLat(data.coord.lat);
          setLon(data.coord.lon);
          setWeatherData(data);

          
          const newSearch = {
            city: cityName,
            lat: data.coord.lat,
            lon: data.coord.lon,
          };
          const updatedSearches = [newSearch, ...recentSearches].slice(0, 5); 
          setRecentSearches(updatedSearches);
          localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
        }
      })
      .catch(error => console.error('Error fetching coordinates:', error));
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleSearch = () => {
    if (city) {
      fetchCoordinates(city);
    } else if (lat && lon) {
      const apiKey = 'bb5c5a1a91c1d5b91b734c519e7f494d';
      const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${apiKey}`;

      fetch(url)
        .then(response => response.json())
        .then(data => {
          setWeatherData(data);

       
          const newSearch = {
            city: data.name,
            lat: lat,
            lon: lon,
          };
          const updatedSearches = [newSearch, ...recentSearches].slice(0, 5); 
          setRecentSearches(updatedSearches);
          localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
        })
        .catch(error => console.error('Error fetching weather data:', error));
    } else {
      alert('Please enter a city name or both latitude and longitude!');
    }
  };

  return (
    <div className="weather-form">
      <div className="search-inputs">
        <input
          type="text"
          placeholder="Type City Name"
          value={city}
          onChange={handleCityChange}
        />
        <span> Or </span>
        <input
          type="text"
          placeholder="Lat:"
          value={lat}
          onChange={(e) => setLat(e.target.value)}
        />
        <input
          type="text"
          placeholder="Lon:"
          value={lon}
          onChange={(e) => setLon(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      {weatherData && (
        <>
          <WeatherDisplay weatherData={weatherData} />
          <MapContainer center={[lat, lon]} zoom={13} style={{ height: "400px", width: "100%" }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={[lat, lon]}>
              <Popup>
                {city || weatherData.name} <br /> {`Lat: ${lat}, Lon: ${lon}`}
              </Popup>
            </Marker>
          </MapContainer>
        </>
      )}

      <div className="recent-searches">
        <h3>Recent Searches</h3>
        <ul>
          {recentSearches.map((search, index) => (
            <li key={index}>
              {search.city} (Lat: {search.lat}, Lon: {search.lon})
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default WeatherForm;
